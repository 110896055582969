<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import vuexStore from "../../../store";
import { checkEmailExists } from "../../../services/auth";

export default {
  beforeRouteEnter(to, from, next) {
    const state = vuexStore.state.cases;
    const { purchase_case, sell_case, property } = state;
    let isValid = true;
    if (property === "both") {
      if (!purchase_case.amount || !sell_case.amount) {
        isValid = false;
      }
    } else if (
      property === "sale" ? !sell_case.amount : !purchase_case.amount
    ) {
      isValid = false;
    }
    if (!isValid || !property) {
      next({ name: 2 });
    } else {
      next();
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref(null);
    const personal = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      country: "UK",
      phone_country: "UK",
      street_address: "",
      city: "",
      message: "",
    });

    const handleSubmit = () => {
      store.dispatch("cases/updatePersonal", { ...personal });
      router.push({ name: "quote" });
    };

    const verifyEmail = async () => {
      try {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(personal.email)) {
          email.value.setCustomValidity("Invalid email address");
          return;
        } else {
          email.value.setCustomValidity("");
        }
        await checkEmailExists(personal.email);
        email.value.setCustomValidity("");
      } catch (e) {
        email.value.setCustomValidity("Email already taken");
      }
    };

    return {
      personal,
      handleSubmit,
      email,
      verifyEmail,
    };
  },
};
</script>

<template>
  <div class="grid gap-y-4 max-w-3xl mx-auto w-full pb-8">
    <div>
      <h2 class="text-3xl font-extrabold text-gray-900 text-center">
        Personal information
      </h2>
    </div>
    <div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="handleSubmit" class="space-y-8">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Personal information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Use a permanent address where you can receive mail.
                </p>
              </div>
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="first_name"
                    class="block text-sm font-medium text-gray-700"
                    >First name</label
                  >
                  <input
                    required
                    v-model="personal.first_name"
                    type="text"
                    name="first_name"
                    id="first_name"
                    autocomplete="given-name"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-gray-700"
                    >Last name</label
                  >
                  <input
                    required
                    type="text"
                    v-model="personal.last_name"
                    name="last_name"
                    id="last_name"
                    autocomplete="family-name"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="email_address"
                    class="block text-sm font-medium text-gray-700"
                    >Email address</label
                  >
                  <input
                    required
                    ref="email"
                    @input="verifyEmail"
                    v-model="personal.email"
                    type="email"
                    name="email_address"
                    id="email_address"
                    autocomplete="email"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <div>
                    <label
                      for="phone_number"
                      class="block text-sm font-medium text-gray-700"
                      >Phone Number</label
                    >
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center">
                        <label for="country" class="sr-only">Country</label>
                        <select
                          required
                          v-model="personal.phone_country"
                          id="country"
                          name="country"
                          class="
                            focus:ring-blue-500 focus:border-blue-500
                            h-full
                            py-0
                            pl-3
                            pr-7
                            border-transparent
                            bg-transparent
                            text-gray-500
                            sm:text-sm
                            rounded-md
                          "
                        >
                          <option>UK</option>
                          <option>CA</option>
                          <option>EU</option>
                        </select>
                      </div>
                      <input
                        type="tel"
                        required
                        v-model="personal.phone_number"
                        name="phone_number"
                        id="phone_number"
                        class="
                          focus:ring-blue-500 focus:border-blue-500
                          block
                          w-full
                          pl-16
                          sm:text-sm
                          border-gray-300
                          rounded-md
                        "
                        placeholder="(XXX) XXX-XXXX"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="country"
                    class="block text-sm font-medium text-gray-700"
                    >Country / Region</label
                  >
                  <select
                    id="country"
                    required
                    name="country"
                    autocomplete="country"
                    class="
                      mt-1
                      block
                      w-full
                      bg-white
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  >
                    <option value="UK">United Kingdom</option>
                    <option value="CA">Canada</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="street_address"
                    class="block text-sm font-medium text-gray-700"
                    >Street address</label
                  >
                  <input
                    required
                    v-model="personal.street_address"
                    type="text"
                    name="street_address"
                    id="street_address"
                    autocomplete="street-address"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="city"
                    class="block text-sm font-medium text-gray-700"
                    >City</label
                  >
                  <input
                    required
                    type="text"
                    name="city"
                    v-model="personal.city"
                    id="city"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    for="state"
                    class="block text-sm font-medium text-gray-700"
                    >State / Province</label
                  >
                  <input
                    type="text"
                    name="state"
                    id="state"
                    v-model="personal.state"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>

                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    for="postal_code"
                    class="block text-sm font-medium text-gray-700"
                    >Postcode</label
                  >
                  <input
                    required
                    v-model="personal.postcode"
                    type="text"
                    name="postal_code"
                    id="postal_code"
                    autocomplete="postal-code"
                    class="
                      mt-1
                      block
                      w-full
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      py-2
                      px-3
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Additional information
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Add any additional information below.
                </p>
              </div>
              <div class="sm:col-span-6">
                <label
                  for="message"
                  class="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <div class="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    v-model="personal.message"
                    rows="3"
                    class="
                      shadow-sm
                      focus:ring-blue-500 focus:border-blue-500
                      block
                      w-full
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="
              inline-flex
              float-right
              w-full
              max-w-xs
              justify-center
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-light-blue-700
              hover:bg-light-blue-800
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:light-blue-500
            "
          >
            Save and get an instant quote
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
